import { Injectable, isDevMode } from '@angular/core'
import { FuelingStatusGroup } from '@fleet-customer/shared/fill-repartition-and-fueling-success'
import { FleetInvoice } from '@boosterfuels/types/types-ts'

interface RmdColors {
  '--color-brand-100': string
  '--color-brand-300': string
  '--color-brand-500': string
  '--color-brand-600': string
  '--color-brand-700': string
  '--color-brand-900': string
  '--color-brand-a00': string
  '--color-brand-c00': string
  '--color-brand-e00': string
  '--color-brand-f00': string
  '--color-blue': string
  '--color-green': string
  '--color-orange': string
  '--color-red': string
  '--color-grey-200': string
  '--color-grey-400': string
  '--color-grey-700': string
  '--color-grey-800': string
  '--color-grey-b00': string
  '--color-grey-c00': string
  '--color-grey-d00': string
  '--color-grey-e00': string
  '--color-grey-f00': string
  '--color-grey-f50': string
  '--color-total-gallons': string
  '--color-unleaded-regular': string
  '--color-unleaded-premium': string
  '--color-diesel-conventional': string
  '--color-diesel-renewable': string
  '--color-diesel-bio': string
  '--color-diesel-dyed': string
  '--color-ethanol': string
  '--color-chart-ui-text': string
  '--color-chart-ui-grid': string
}

const colors: RmdColors = {
  '--color-brand-100': '',
  '--color-brand-300': '',
  '--color-brand-500': '',
  '--color-brand-600': '',
  '--color-brand-700': '',
  '--color-brand-900': '',
  '--color-brand-a00': '',
  '--color-brand-c00': '',
  '--color-brand-e00': '',
  '--color-brand-f00': '',
  '--color-blue': '',
  '--color-green': '',
  '--color-orange': '',
  '--color-red': '',
  '--color-grey-200': '',
  '--color-grey-400': '',
  '--color-grey-700': '',
  '--color-grey-800': '',
  '--color-grey-b00': '',
  '--color-grey-c00': '',
  '--color-grey-d00': '',
  '--color-grey-e00': '',
  '--color-grey-f00': '',
  '--color-grey-f50': '',
  '--color-total-gallons': '',
  '--color-unleaded-regular': '',
  '--color-unleaded-premium': '',
  '--color-diesel-conventional': '',
  '--color-diesel-renewable': '',
  '--color-diesel-bio': '',
  '--color-diesel-dyed': '',
  '--color-ethanol': '',
  '--color-chart-ui-text': '',
  '--color-chart-ui-grid': '',
}

@Injectable({ providedIn: 'root' })
export class ColorsService {
  private documentStyles = getComputedStyle(document.documentElement)

  public init(): void {
    ;(Object.keys(colors) as (keyof RmdColors)[]).forEach((propName) => {
      colors[propName] = this.extractPropertyValue(propName)
    })
  }

  private extractPropertyValue(propName: keyof RmdColors): string {
    const value = this.documentStyles.getPropertyValue(propName).trim()

    if (!value) {
      throw new Error(`Unknown CSS Custom Property: '${propName}'`)
    }

    return value
  }
}

export function getColor(propName: keyof RmdColors): string {
  if (isDevMode() && colors[propName] === '') {
    throw new Error(`getColor() called before ColorsService.init`)
  }
  return colors[propName]
}

export function getEnergyTypeColor(energyType: string): string {
  switch (energyType) {
    case 'REGULAR':
    case 'Regular':
    case 'Unleaded Regular':
      return getColor('--color-unleaded-regular')

    case 'PREMIUM':
    case 'Premium':
    case 'Unleaded Premium':
      return getColor('--color-unleaded-premium')

    case 'DIESEL':
    case 'Diesel':
    case 'Diesel Conventional':
      return getColor('--color-diesel-conventional')

    case 'RENEWABLE_DIESEL':
    case 'Renewable Diesel':
    case 'Diesel Renewable':
    case 'Diesel/Renewable Diesel':
    case 'Diesel Renewable/Conventional':
      return getColor('--color-diesel-renewable')

    case 'DYED_DIESEL':
    case 'Dyed Diesel':
      return getColor('--color-diesel-dyed')

    default:
      if (isDevMode()) {
        throw new Error(`Unknown Energy Type Color for '${energyType}'`)
      }
      return getColor('--color-blue')
  }
}

export function getVehicleStatusColor(
  status: FuelingStatusGroup | 'pitStopNotServiced'
): string {
  switch (status) {
    case 'fueled':
      return getColor('--color-green')

    case 'notFueledControllable':
      return getColor('--color-orange')

    case 'notFueled':
      return getColor('--color-red')

    case 'pitStopNotServiced':
      return getColor('--color-grey-c00')

    default:
      if (isDevMode()) {
        console.warn(`Unknown Vehicle Status: '${status}'`)
      }
      return getColor('--color-red')
  }
}

export function getFleetOrderStatusColor(status: string): string {
  switch (status.toUpperCase()) {
    case 'SUBMITTED':
      return getColor('--color-orange')

    case 'APPROVED':
      return getColor('--color-blue')

    case 'INVOICED':
      return getColor('--color-green')

    default:
      return getColor('--color-grey-200')
  }
}

export function getInvoiceStatusColor({
  status,
  amountPaid,
}: FleetInvoice): string {
  switch (status) {
    case 'Fully paid':
    case 'paidInFull':
      return getColor('--color-green')

    default:
      return getColor(amountPaid === 0 ? '--color-orange' : '--color-blue')
  }
}
